<template>
  <b-table
    :fields="headers"
    :items="items"
    :busy="isLoading"
    borderless
    responsive
    show-empty
  >
    <template #table-busy>
      <div class="text-center my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Loading...</strong>
      </div>
    </template>

    <template #cell(details)>
      <v-btn color="#EEEEEE" dark small fab :elevation="0" to="#">
        <v-icon color="black">mdi-loupe</v-icon>
      </v-btn>
    </template>

    <template #cell(cpl)="data">
      <span>{{ data.item.cpl }} €</span>
    </template>

    <template #cell(episode.title)="data">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">{{
            truncateTitle(data.item.episode.title)
          }}</span>
        </template>
        <span>{{ data.item.episode.title }}</span>
      </v-tooltip>
    </template>

    <template #cell(startAt)="data">
      {{ $d(new Date(data.item.startAt), "short") }}
    </template>

    <template #cell(endAt)="data">
      {{ $d(new Date(data.item.endAt), "short") }}
    </template>

    <template #cell(budget)="data"> {{ data.item.budget }} €</template>

    <template #cell(priority)="data">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="getPriority(data.item).color"
            dark
            v-bind="attrs"
            v-on="on"
          >
            {{ getPriority(data.item).icon }}
          </v-icon>
        </template>
        <span>{{ getPriority(data.item).tooltipLabel }}</span>
      </v-tooltip>
    </template>

    <template #cell(artificialBudgetRatio)="data">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="getArtificialBudgetRatio(data.item).color"
            dark
            v-bind="attrs"
            v-on="on"
          >
            {{ getArtificialBudgetRatio(data.item).icon }}
          </v-icon>
        </template>
        <span>{{ getArtificialBudgetRatio(data.item).tooltipLabel }}</span>
      </v-tooltip>
    </template>

    <template #cell(play.paids)="data">
      <b-spinner v-if="data.item.statsLoading" label="Spinning"></b-spinner>
      <span v-else-if="!data.item.statsLoading && data.item.stats">{{
        data.item.stats.listens
      }}</span>
      <span v-else>-</span>
    </template>

    <template #cell(spend)="data">
      <b-spinner v-if="data.item.statsLoading" label="Spinning"></b-spinner>
      <div v-else-if="!data.item.statsLoading && data.item.stats">
        <div>
          <span
            class="text-success font-weight-bold"
            :class="getColorPercentText(data.item.stats.spendPercent)"
            >{{ data.item.stats.spendPercent }} %</span
          >
        </div>
      </div>
      <span v-else>-</span>
    </template>

    <template #cell(averagetimelistenings)="data">
      <b-spinner v-if="data.item.statsLoading" label="Spinning"></b-spinner>
      <span v-else-if="!data.item.statsLoading && data.item.stats">{{
        data.item.stats.averagetimelistenings
      }}</span>
      <span v-else>-</span>
    </template>

    <template #cell(totaltimelistenings)="data">
      <b-spinner v-if="data.item.statsLoading" label="Spinning"></b-spinner>
      <span v-else-if="!data.item.statsLoading && data.item.stats">{{
        data.item.stats.totaltimelistenings
      }}</span>
      <span v-else>/</span>
    </template>

    <template #cell(status)>-</template>
  </b-table>
</template>

<script>
import { getColorPercentText, STATUS_CAMPAIGN } from "@/assets/js/helper";

export default {
  props: {
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: false
    }
  },

  methods: {
    getPriority(item) {
      let priority = {
        color: "primary",
        icon: "",
        tooltipLabel: "Delivery mode: "
      };

      switch (item.priority) {
        case 1:
          priority.color = "blue";
          priority.icon = "mdi-minus";
          priority.tooltipLabel += "Standard";
          break;
        case 5:
          priority.color = "orange";
          priority.icon = "mdi-chevron-up";
          priority.tooltipLabel += "High";
          break;
        case 10:
          priority.color = "red";
          priority.icon = "mdi-chevron-double-up";
          priority.tooltipLabel += "Highest";
          break;
      }

      return priority;
    },

    getArtificialBudgetRatio(item) {
      const artificialBudgetRatio = item.artificialBudgetRatio;
      const defaultProperties = {
        icon: "mdi-speedometer",
        tooltipLabel: `Artificial Budget Ratio: ${artificialBudgetRatio * 100}%`
      };

      if (artificialBudgetRatio > 0) {
        return {
          ...defaultProperties,
          icon: "mdi-speedometer",
          color: "red"
        };
      } else if (artificialBudgetRatio < 0) {
        return {
          ...defaultProperties,
          icon: "mdi-speedometer-slow",
          color: "blue"
        };
      }
      return {
        ...defaultProperties,
        icon: "mdi-speedometer-medium",
        color: "green"
      };
    },

    truncateTitle(title) {
      const MAX_CHARACTERS = 30;

      if (title.length > MAX_CHARACTERS) {
        title = `${title.substring(0, MAX_CHARACTERS)}...`;
      }

      return title;
    },

    getColorPercentText(percent) {
      return getColorPercentText(percent);
    },

    getColorStatutCampaign(status) {
      return STATUS_CAMPAIGN[status];
    }
  }
};
</script>
