export const headers = [
  { label: "", key: "show_details" },
  { label: "Name", key: "name", sortable: true },
  { label: "Targeting", key: "categories", sortable: true },
  { label: "Start", key: "startAt", sortable: true },
  { label: "End", key: "endAt", sortable: true },
  { label: "Budget Global", key: "budget", sortable: true },
  { label: "Spend", key: "stats.budget:spent", sortable: true },
  { label: "Play paids", key: "stats.plays:paid", sortable: true },
  { label: "Avg list time", key: "averagetimelistenings", sortable: true },
  { label: "Total list time", key: "totaltimelistenings", sortable: true },
  { label: "Cpl", key: "cpl", sortable: true },
  { label: "Status", key: "statutOrder", sortable: true },
  { label: "Actions", key: "actions", sortable: false }
];

export const headersV2 = [
  { label: "", key: "show_details" },
  { label: "Name", key: "name", sortable: false },
  { label: "Advertiser", key: "advertiser", sortable: false },
  { label: "Agency", key: "agency", sortable: false },
  { label: "Categories", key: "tags", sortable: false },
  { label: "Start", key: "startAt", sortable: false },
  { label: "End", key: "endAt", sortable: false },
  { label: "Spend", key: "spend", sortable: false },
  { label: "Listens", key: "play.paids", sortable: false },
  { label: "Avg list time", key: "averagetimelistenings", sortable: true },
  { label: "Total list time", key: "totaltimelistenings", sortable: true },
  { label: "Cpl", key: "cpl", sortable: false },
  { label: "Budget", key: "budget", sortable: false },
  { label: "ABR", key: "containArtificialBudgetRatio", sortable: false },
  { label: "Status", key: "status", sortable: true },
  { label: "Actions", key: "actions", sortable: false }
];

export const headersItemsLine = [
  { label: "Episode Name", key: "episode.title", sortable: false },
  { label: "Priority", key: "priority", sortable: false },
  { label: "ABR", key: "artificialBudgetRatio", sortable: false },
  { label: "Start date", key: "startAt", sortable: false },
  { label: "End Date", key: "endAt", sortable: false },
  { label: "Cpl", key: "cpl", sortable: false },
  { label: "Spend", key: "spend", sortable: false },
  { label: "Listens", key: "play.paids", sortable: false },
  { label: "Avg list time", key: "averagetimelistenings", sortable: true },
  { label: "Total list time", key: "totaltimelistenings", sortable: false },
  { label: "Budget", key: "budget", sortable: false },
  { label: "Status", key: "status", sortable: false }
];
