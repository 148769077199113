<template>
  <div class="kt-container">
    <div class="row row-cards">
      <div class="card-pi-stats col">
        <div class="card">
          <b-row>
            <b-col xs="12" v-if="!isAdmin">
              <h2 class="text-left pl-5">Forbidden</h2>
            </b-col>

            <b-col xs="12" v-if="isAdmin">
              <b-card class="h-100">
                <template v-slot:header>
                  <div class="d-flex align-items-center">
                    <b-row>
                      <b-col
                        sm="12"
                        md="2"
                        class="align-middle align-items-center m-auto text-md-left text-center"
                        justify="center"
                      >
                        {{ totalRows }}
                        {{ $tc("CAMPAIGN_LIST_V2.CAMPAIGNS") }}
                      </b-col>

                      <b-col
                        sm="12"
                        md="8"
                        class="align-middle align-items-center m-auto text-center"
                        justify="center"
                      >
                        <v-text-field
                          v-model="searchFilter"
                          hide-details
                          :label="$tc('CAMPAIGN_LIST_V2.SEARCH')"
                          prepend-inner-icon="search"
                          solo
                          filled
                          color="blue"
                          dense
                          clearable
                          @input="inputSearchHandler"
                        ></v-text-field>
                      </b-col>

                      <b-col
                        sm="12"
                        md="2"
                        class="align-middle align-items-center m-auto text-md-right text-center"
                      >
                        <router-link
                          :to="{ name: 'adstudio_delivery_order_create' }"
                        >
                          <b-button
                            size="sm"
                            class="btn btn-primary align-middle align-items-center m-auto text-center"
                          >
                            <i class="flaticon2-plus fa-sm"></i>
                            <strong>{{ $tc("CAMPAIGNS_SETTINGS.NEW") }}</strong>
                          </b-button>
                        </router-link>
                      </b-col>
                    </b-row>
                  </div>
                </template>

                <Datatable
                  :items="items"
                  :loading="isLoading"
                  @deleteModal="deleteModal"
                  @duplicateModal="duplicateModal"
                  @changeActivated="changeActivated"
                />

                <template v-slot:footer>
                  <v-row justify="center">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="filters.limit"
                      @next="changePage"
                      @previous="changePage"
                    ></b-pagination>
                  </v-row>
                </template>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <input
      type="hidden"
      id="copyClipboard"
      class="form-control"
      style="width: 100px"
    />

    <v-dialog v-model="dialog.delete.status" width="500" persistent>
      <v-card>
        <v-card-title class="headline"
          >{{ $tc("CAMPAIGN_LIST_V2.DELETE_CAMPAIGN.DELETE_LABEL") }}
        </v-card-title>

        <v-card-text
          >{{ $tc("CAMPAIGN_LIST_V2.DELETE_CAMPAIGN.CONFIRM") }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <button
            class="btn btn-outline-primary"
            :disabled="isLoadingDelete"
            v-on:click="deleteModal('close')"
          >
            {{ $tc("CAMPAIGN_LIST_V2.DELETE_CAMPAIGN.CANCEL") }}
          </button>

          <button
            class="btn btn-primary ml-3"
            :disabled="isLoadingDelete"
            v-on:click="deleteCampaign()"
          >
            <template v-if="isLoadingDelete">
              <b-spinner label="Spinning"></b-spinner>
            </template>
            <template v-if="!isLoadingDelete">
              {{ $tc("CAMPAIGN_LIST_V2.DELETE_CAMPAIGN.SUBMIT") }}
            </template>
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.duplicate.status"
      v-if="dialog.duplicate.item"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="headline"
          >{{ $tc("CAMPAIGN_LIST_V2.DUPLICATE_CAMPAIGN.LABEL") }}
        </v-card-title>
        <v-card-text
          >{{ $tc("CAMPAIGN_LIST_V2.DUPLICATE_CAMPAIGN.CONFIRM") }}
          <b-form-input
            v-model="dialog.duplicate.campaignName"
            class="form-control"
          ></b-form-input>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <button
            class="btn btn-outline-primary"
            :disabled="isLoadingDuplicate"
            v-on:click="duplicateModal('close')"
          >
            {{ $tc("CAMPAIGN_LIST_V2.DUPLICATE_CAMPAIGN.CANCEL") }}
          </button>

          <button
            class="btn btn-primary ml-3"
            :disabled="isLoadingDuplicate"
            v-on:click="duplicateCampaign()"
          >
            <template v-if="isLoadingDuplicate">
              <b-spinner label="Spinning"></b-spinner>
            </template>
            <template v-if="!isLoadingDuplicate">
              {{ $tc("CAMPAIGN_LIST_V2.DUPLICATE_CAMPAIGN.SUBMIT") }}
            </template>
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getCampaignsV2,
  getCampaignsV2Stats
} from "@/api/campaigns/manage/getters";
import {
  updateCampaignV2,
  deleteCampaignV2,
  duplicateCampaignV2
} from "@/api/campaigns/manage/setters";
import Datatable from "@/components/sponsorings/datatable";
import { renderSeconds } from "@/common/functions";

export default {
  components: {
    Datatable
  },

  data() {
    return {
      items: [],
      totalRows: 0,
      currentPage: 1,
      isLoading: false,
      isLoadingDelete: false,
      isLoadingDuplicate: false,
      searchFilter: "",
      timeoutSearch: 0,
      filters: {
        q: "",
        limit: 14,
        page: 1
      },
      dialog: {
        delete: {
          status: false,
          loading: false,
          item: null
        },
        duplicate: {
          campaignName: "",
          status: false,
          loading: false,
          item: null
        }
      }
    };
  },

  computed: {
    ...mapGetters(["isAdmin"])
  },

  mounted() {
    this.loadCampaigns();
  },

  methods: {
    async loadCampaigns() {
      this.isLoading = true;

      try {
        const response = await getCampaignsV2(this.axios, this.filters);
        this.items = response.data || [];
        this.totalRows = response.total_items;

        if (this.items.length > 0) {
          this.loadStats();
        }
      } catch (error) {
        console.error("---ERROR-CAMPAIGNS-V2---");
        console.error(error);
      }

      this.isLoading = false;
    },

    async loadStats() {
      let campaignsId = [];

      this.items.forEach(element => {
        element.statsLoading = true;
        campaignsId.push(element.id);
      });

      let copyItems = [...this.items];

      try {
        const response = await getCampaignsV2Stats(
          { campaigns: campaignsId },
          this.axios
        );

        copyItems.forEach(element => {
          const statsItems = response[element.id];

          if (statsItems) {
            element.stats = {
              spendPercent: statsItems["spent:rate"],
              listens: statsItems["paid:plays"],
              averagetimelistenings: renderSeconds(
                statsItems["paid:timelistenings:avg"]
              ),
              totaltimelistenings: renderSeconds(
                statsItems["paid:timelistenings"]
              )
            };
          }
        });
      } catch (error) {
        console.error("---ERROR-STATS-CAMPAINGS");
        console.error(error);
      }

      copyItems.forEach(element => {
        element.statsLoading = false;
      });

      this.items = copyItems;
    },

    changePage() {
      this.filters.page = this.currentPage;
      this.filters.q = this.searchFilter;
      this.loadCampaigns();
    },

    async deleteCampaign() {
      this.isLoadingDelete = true;

      try {
        await deleteCampaignV2(this.dialog.delete.item.id, this.axios);
        this.deleteModal("close");
        this.loadCampaigns();
      } catch (error) {
        console.error("---ERROR-CAMPAIGN-V2");
        console.error(error);
      }

      this.isLoadingDelete = false;
    },

    async duplicateCampaign() {
      this.isLoadingDuplicate = true;

      try {
        await duplicateCampaignV2(
          this.dialog.duplicate.item.id,
          this.dialog.duplicate.campaignName,
          this.axios
        );
        this.duplicateModal("close");
        this.loadCampaigns();
      } catch (error) {
        console.error("---ERROR-DUPLICATE-CAMPAIGN-V2");
        console.error(error);
      }

      this.isLoadingDuplicate = false;
    },

    deleteModal({ type, item }) {
      if ("open" == type) {
        this.dialog.delete = {
          status: true,
          item: item
        };
      } else {
        this.dialog.delete = {
          status: false,
          loading: false,
          item: null
        };
      }
    },

    duplicateModal({ type, item }) {
      if ("open" == type) {
        this.dialog.duplicate = {
          status: true,
          item: item,
          campaignName: "Copy - " + item.name
        };
      } else {
        this.dialog.duplicate = {
          status: false,
          loading: false,
          item: null
        };
      }
    },

    inputSearchHandler(text) {
      if (text && text.length > 2) {
        this.searchFilter = text.toLowerCase();

        clearTimeout(this.timeoutSearch);

        this.timeoutSearch = setTimeout(() => {
          this.filters.q = this.searchFilter;
          this.loadCampaigns();
        }, 1000);
      }
      if (!text) {
        this.searchFilter = "";
        this.filters.q = this.searchFilter;
        this.loadCampaigns();
      }
      this.currentPage = 1;
      this.filters.page = 1;
    },

    async changeActivated(data) {
      try {
        await updateCampaignV2(
          { activated: data.activated },
          data.campaignId,
          this.axios
        );

        this.loadCampaigns();
      } catch (error) {
        console.error("---ERROR-ACTIVATED---");
        console.error(error);
      }
    }
  },

  watch: {
    currentPage() {
      this.changePage();
    }
  }
};
</script>
