<template>
  <b-table
    :fields="getHeader"
    :items="getItems"
    :busy="loading"
    :items-per-page="10"
    responsive
    show-empty
    ref="table"
  >
    <template #empty>
      <div class="d-flex justify-content-center">
        <button class="btn btn-primary">
          <router-link
            to="{ name: 'adstudio_delivery_order_create'}"
            style="color: white"
            >{{ $tc("CAMPAIGN_LIST_V2.CREATE_CAMPAIGN") }}</router-link
          >
        </button>
      </div>
    </template>

    <template #table-busy>
      <div class="text-center my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Loading...</strong>
      </div>
    </template>

    <template #cell(cpl)="data">{{ data.item.cpl }} €</template>
    <template #cell(budget)="data">{{ data.item.budget }} €</template>

    <template #cell(show_details)="row">
      <v-btn icon @click="expandItemsLine(row)"
        ><v-icon>{{ getIcon(row.detailsShowing) }}</v-icon></v-btn
      >
    </template>

    <template #cell(actions)="data">
      <b-dropdown text="Actions">
        <b-dropdown-item
          :to="{
            name: 'adstudio_delivery_order_details',
            params: { id: data.item.id }
          }"
          rel="noopener noreferrer"
        >
          <v-icon color="blue">mdi-eye</v-icon>
          {{ $tc("CAMPAIGN_LIST_V2.MENU_ACTIONS.DETAILS") }}
        </b-dropdown-item>

        <b-dropdown-item
          :to="{
            name: 'adstudio_delivery_order_edit',
            params: { id: data.item.id }
          }"
          rel="noopener noreferrer"
          ><v-icon color="primary">mdi-pencil</v-icon
          >{{ $tc("CAMPAIGN_LIST_V2.MENU_ACTIONS.EDIT_CAMPAIGN") }}
        </b-dropdown-item>

        <b-dropdown-item @click="duplicateModal('open', data.item)"
          ><v-icon color="green">mdi-content-copy</v-icon
          >{{
            $tc("CAMPAIGN_LIST_V2.MENU_ACTIONS.DUPLICATE_CAMPAIGN")
          }}</b-dropdown-item
        >

        <b-dropdown-item @click="deleteModal('open', data.item)"
          ><v-icon color="error">mdi-delete</v-icon
          >{{
            $tc("CAMPAIGN_LIST_V2.MENU_ACTIONS.DELETE_CAMPAIGN")
          }}</b-dropdown-item
        >

        <b-dropdown-item
          v-if="
            data.item.activated &&
              data.item.status &&
              data.item.status.name !== 'finished'
          "
          @click="changeActivated(data)"
        >
          <v-icon color="blue">mdi-pause-circle-outline</v-icon>
          {{ $tc("CAMPAIGN_LIST_V2.MENU_ACTIONS.PAUSE_CAMPAIGN") }}
        </b-dropdown-item>

        <b-dropdown-item
          v-if="
            !data.item.activated &&
              data.item.status &&
              data.item.status.name !== 'finished'
          "
          @click="changeActivated(data)"
        >
          <v-icon color="green">mdi-play-circle-outline</v-icon>
          {{ $tc("CAMPAIGN_LIST_V2.MENU_ACTIONS.PLAY_CAMPAIGN") }}
        </b-dropdown-item>
      </b-dropdown>
    </template>

    <template #cell(tags)="data">
      <v-tooltip v-if="data.item.tags && data.item.tags.length > 0" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="primary" dark v-bind="attrs" v-on="on">
            mdi-target
          </v-icon>
        </template>
        <div class="d-flex flex-column">
          <span v-for="(tag, index) in data.item.tags" :key="`tags_${index}`"
            >{{ tag.name }}
          </span>
        </div>
      </v-tooltip>
      <span v-else>-</span>
    </template>

    <template #cell(advertiser)="data">
      {{ data.item.advertiser ? data.item.advertiser.name : "-" }}
    </template>

    <template #cell(agency)="data">
      {{ data.item.agency ? data.item.agency.name : "-" }}
    </template>

    <template #cell(startAt)="data">
      {{ data.item.minDate ? $d(new Date(data.item.minDate), "short") : "-" }}
    </template>

    <template #cell(endAt)="data">
      {{ data.item.maxDate ? $d(new Date(data.item.maxDate), "short") : "-" }}
    </template>

    <template #cell(spend)="data">
      <b-spinner v-if="data.item.statsLoading" label="Spinning"></b-spinner>
      <div v-else-if="!data.item.statsLoading && data.item.stats">
        <div>
          <span
            class="text-success font-weight-bold"
            :class="getColorPercentText(data.item.stats.spendPercent)"
            >{{ data.item.stats.spendPercent }} %</span
          >
        </div>
      </div>
      <span v-else>-</span>
    </template>

    <template #cell(name)="data">
      {{ data.item.name }}

      <v-icon
        v-if="data.item.autopromo"
        small
        color="green darken-2"
        title="Autopromo"
      >
        mdi-tag
      </v-icon>

      <div class="text-center"></div>
    </template>

    <template #cell(play.paids)="data">
      <b-spinner v-if="data.item.statsLoading" label="Spinning"></b-spinner>
      <span v-else-if="!data.item.statsLoading && data.item.stats">{{
        data.item.stats.listens
      }}</span>
      <span v-else>-</span>
    </template>

    <template #cell(averagetimelistenings)="data">
      <b-spinner v-if="data.item.statsLoading" label="Spinning"></b-spinner>
      <span v-else-if="!data.item.statsLoading && data.item.stats">{{
        data.item.stats.averagetimelistenings
      }}</span>
      <span v-else>-</span>
    </template>

    <template #cell(totaltimelistenings)="data">
      <b-spinner v-if="data.item.statsLoading" label="Spinning"></b-spinner>
      <span v-else-if="!data.item.statsLoading && data.item.stats">{{
        data.item.stats.totaltimelistenings
      }}</span>
      <span v-else>-</span>
    </template>

    <template #cell(containArtificialBudgetRatio)="data">
      <v-tooltip v-if="data.item.containArtificialBudgetRatio > 0" top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="containArtificialBudgetRatio(data.item).color"
            dark
            v-bind="attrs"
            v-on="on"
          >
            {{ containArtificialBudgetRatio(data.item).icon }}
          </v-icon>
        </template>
        <span>{{ containArtificialBudgetRatio(data.item).tooltipLabel }}</span>
      </v-tooltip>
      <p v-else class="text-center w-100">-</p>
    </template>

    <template #cell(status)="data">
      <span
        v-if="data.item.status"
        class="label label-lg label-inline text-capitalize"
        :class="data.item.status.class"
        >{{ data.item.status.name }}</span
      >
      <span v-else>-</span>
    </template>

    <template #row-details>
      <div class="bb-row-details">
        <Datatable
          :headers="getHeaderItemsLine"
          :items="itemsLine"
          :isLoading="isLoading"
        />
      </div>
    </template>
  </b-table>
</template>

<script>
import {
  headersV2,
  headersItemsLine
} from "@/components/sponsorings/sponsoring.config";
import Datatable from "@/components/sponsorings/itemsLine/datatable";
import {
  getItemsLineFromSponsoring,
  getItemsLineStats
} from "@/api/campaigns/lineItems/getters";
import { getColorPercentText } from "@/assets/js/helper";
import { renderSeconds } from "@/common/functions";
import { getStatusCampaign } from "@/common/functions";

export default {
  components: {
    Datatable
  },

  props: {
    items: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      currentItems: [],
      itemsLine: [],
      isLoading: false
    };
  },

  computed: {
    getHeader() {
      return headersV2;
    },

    getHeaderItemsLine() {
      return headersItemsLine;
    },

    getItems() {
      let items = [...this.items];

      items.forEach(element => {
        if (element.minDate && element.maxDate) {
          this.setStatusForCampaign(element);
        }
      });

      return items;
    }
  },

  methods: {
    async expandItemsLine(row) {
      this.currentItems.forEach(element => element.toggleDetails());

      row.toggleDetails();
      this.currentItems = [];
      // Vue boostrap ne passe pas le "detailsShowing" directement à true, il y a un delai
      if (!row.detailsShowing) {
        this.isLoading = true;
        try {
          this.itemsLine = await getItemsLineFromSponsoring(
            row.item.id,
            {},
            this.axios
          );
          this.currentItems.push(row);

          this.loadStats(row.item.id);
        } catch (error) {
          console.error("---ITEMS-LINE---");
          console.error(error);
        }
        this.isLoading = false;
      } else {
        row.toggleDetails();
      }
    },

    async loadStats(campaignId) {
      let itemsLinesId = [];

      this.itemsLine.forEach(element => {
        element.statsLoading = true;
        itemsLinesId.push(element.id);
      });

      let copyItems = [...this.itemsLine];

      try {
        const response = await getItemsLineStats(
          campaignId,
          itemsLinesId,
          this.axios
        );

        copyItems.forEach(element => {
          const statsItem = response[element.id];

          if (statsItem) {
            element.stats = {
              spendPercent: statsItem["spent:rate"],
              listens: statsItem["paid:plays"],
              averagetimelistenings: renderSeconds(
                statsItem["paid:timelistenings:avg"]
              ),
              totaltimelistenings: renderSeconds(
                statsItem["paid:timelistenings"]
              )
            };
          }
        });
      } catch (error) {
        console.error("---ERROR-ITEMS-LINE-STATS---");
        console.error(error);
      }

      copyItems.forEach(element => {
        element.statsLoading = false;
      });

      this.itemsLine = copyItems;
    },

    deleteModal(type, item, index) {
      this.$emit("deleteModal", {
        type,
        item,
        index
      });
    },

    duplicateModal(type, item, index) {
      this.$emit("duplicateModal", {
        type,
        item,
        index
      });
    },

    getIcon(isShow) {
      if (isShow) {
        return "mdi-chevron-up";
      } else {
        return "mdi-chevron-down";
      }
    },

    getColorPercentText(percent) {
      return getColorPercentText(percent);
    },

    setStatusForCampaign(item) {
      return getStatusCampaign(item);
    },

    changeActivated(data) {
      const campaign = data.item;
      const index = data.index;
      this.$emit("changeActivated", {
        campaignId: campaign.id,
        activated: !campaign.activated,
        index: index
      });
    },

    containArtificialBudgetRatio(item) {
      const containArtificialBudgetRatio = item.containArtificialBudgetRatio;
      const defaultProperties = {
        icon: "mdi-speedometer-medium",
        tooltipLabel: `Artificial Budget Ratio`
      };

      if (containArtificialBudgetRatio > 0) {
        return {
          ...defaultProperties,
          color: "orange"
        };
      }

      return {
        icon: "-",
        tooltipLabel: "",
        color: "black"
      };
    }
  }
};
</script>

<style>
.bb-row-details {
  background: #f7f7f7;
  border-radius: 10px;
}

.dropdown-menu {
  padding-left: 0 !important;
}
</style>
